function openShareLink(id) {
    "use strict";

    var e = encodeURIComponent(window.location.href);
    var title = encodeURIComponent($('H1').text());
    var desc = encodeURIComponent($('meta[name=description]').attr('content'));

    var facebookURL = 'http://www.facebook.com/sharer.php?u=' + e;
    var linkedInURL = 'http://www.linkedin.com/shareArticle?mini=true&url=' + e + '&title=' + title + '&summary=' + desc;
    var twitterURL = 'http://twitter.com/share?url=' + e + '&amp;text=' + title;
    var mailTo = 'mailto:?subject=' + title + '&body=' + title + '%0D%0A%0D%0A' + desc + '%0D%0A%0D%0A' + e;

    if (id === 'ss-facebook') {
        window.open(facebookURL, '_blank');
    } else if (id === 'ss-linkedin') {
        window.open(linkedInURL, '_blank');
    } else if (id === 'ss-twitter') {
        window.open(twitterURL, '_blank');
    } else if (id === 'ss-mailto') {
        window.open(mailTo, '_self');
    }

}

$('a[id^="ss-"]').on('click', function(e){
    "use strict";

    openShareLink(this.id);

    e.preventDefault();
    console.log(this);
});