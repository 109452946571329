/**
 * Table
 * @module Table
 * @version 1.13.0
 */
import Component from '../services/component';

/* eslint-disable */
var Table = (function($) {

	// ------------------------------------------------------------------
	// Table functions
	// ------------------------------------------------------------------

	/**
	 * Initialise table
	 * @function Table
	 * @param {Node} Containing table element
	 */
	function Table(el) {
		// Register component
		this.success = Component.registerComponent(el, this);
		if (!this.success) return false;

		setResponsiveness($(el));
		setSorting($(el));
		return this.success;
	}

	/**
	 * Sets th metadata for responsive tables on page (has table-responsive class)
	 * @function setResponsiveness
	 *
	 */
	function setResponsiveness(tables) {
		tables.filter('.table-responsive').each(function (index, table) {
			var headertext = [],
				headers = $(table).find('th'),
				tablebody = $(table).find('tbody')[0];

			for (var h = 0; h < headers.length; h++) {
				var current = headers[h];
				headertext.push(current.textContent.replace(/\r?\n|\r/, ''));
			}
			for (var r = 0, row; row = tablebody.rows[r]; r++) {
				for (var c = 0, col; col = row.cells[c]; c++) {
					var html = '<div class="table-responsive-head">' + headertext[c] + '</div>';
					html += '<div class="table-responsive-content">' + col.innerHTML + '</div>';
					col.innerHTML = html;
				}
			}
		});
	}

	/**
	 * Initialise sorting on sortable tables on page (has table-sortable class)
	 * @function setResponsiveness
	 *
	 */
	function setSorting(tables) {
		var sortableTables = tables.filter('.table-sortable');
		//Clean up icons
		sortableTables.find('th .table-arrows').remove();

		//Append sort icons to every sortable column (th where data-sort attribute is present)
		sortableTables.find('th[data-sort]').append(
			'<div class="table-arrows">' +
			'<span class="table-sort-arrow-up"></span>' +
			'<span class="table-sort-arrow-down"></span>' +
			'</div>');

		//initialise sortable tables
		sortableTables.each(function (index, table) {
			//if iterating table has sortable columns
			if ($(table).find('th[data-sort]').length) {
				$(table).tablesorter();
			}
		});
	}

	// ------------------------------------------------------------------
	// jQuery function extensions for table sorting
	// ------------------------------------------------------------------
	$.fn.tablesorter = function (sortFns) {
		return this.each(function () {
			var $table = $(this);
			sortFns = sortFns || {};
			sortFns = $.extend({}, $.fn.tablesorter.defaultSortFns, sortFns);
			$table.data('sortFns', sortFns);

			$table.on('click.tablesorter', 'thead th', function () {
				$(this).tablesort();
			});

			$table.on('click.tablesorter', 'thead th div', function () {
				$(this).tablesort();
			});


		});
	};


	// Expects $('#mytable').tablesorter() to have already been called.
	// Call on a table header.
	$.fn.tablesort = function (forceDirection) {
		var $thisTh = $(this);
		var thIndex = 0; // we'll increment this soon
		var dir = $.fn.tablesorter.dir;
		var $table = $thisTh.closest('table');
		var datatype = $thisTh.data('sort') || null;

		// No datatype? Nothing to do.
		if (datatype === null) {
			return;
		}

		// Account for colspans
		$thisTh.parents('tr').find('th').slice(0, $(this).index()).each(function () {
			var cols = $(this).attr('colspan') || 1;
			thIndex += parseInt(cols, 10);
		});

		$thisTh.parents('tr').find('th div').slice(0, $(this).parent().index()).each(function () {
			var cols = $(this).attr('colspan') || 1;
			thIndex += parseInt(cols, 10);
		});

		var sortDir;
		if (arguments.length === 1) {
			sortDir = forceDirection;
		}
		else {
			sortDir = forceDirection || $thisTh.data('sort-default') || dir.ASC;
			if ($thisTh.data('sort-dir')) {
				sortDir = $thisTh.data('sort-dir') === dir.ASC ? dir.DESC : dir.ASC;
			}
		}


		$table.trigger('beforetablesort', {column: thIndex, direction: sortDir});

		// More reliable method of forcing a redraw
		$table.css('display');

		// Run sorting asynchronously on a timout to force browser redraw after
		// `beforetablesort` callback. Also avoids locking up the browser too much.
		setTimeout(function () {
			// Gather the elements for this column
			var column = [];
			var sortFns = $table.data('sortFns');
			var sortMethod = sortFns[datatype];
			var trs = $table.children('tbody').children('tr');

			// Extract the data for the column that needs to be sorted and pair it up
			// with the TR itself into a tuple. This way sorting the values will
			// incidentally sort the trs.
			trs.each(function (index, tr) {
				var $e = $(tr).children().eq(thIndex);
				var sortVal = $e.data('sort-value');

				// Store and read from the .data cache for display text only sorts
				// instead of looking through the DOM every time
				if (typeof(sortVal) === 'undefined') {
					var txt = $e.text();
					$e.data('sort-value', txt);
					sortVal = txt;
				}
				column.push([sortVal, tr]);
			});

			// Sort by the data-order-by value
			column.sort(function (a, b) {
				return sortMethod(a[0], b[0]);
			});
			if (sortDir !== dir.ASC) {
				column.reverse();
			}

			// Replace the content of tbody with the sorted rows. Strangely
			// enough, .append accomplishes this for us.
			trs = $.map(column, function (kv) {
				return kv[1];
			});
			$table.children('tbody').append(trs);

			// Reset siblings
			$table.find('th').data('sort-dir', null).removeClass('sorting-desc sorting-asc');
			$table.find('th').attr('aria-sort', 'none');
			$table.find('th div').data('sort-dir', null).removeClass('sorting-desc sorting-asc');
			$thisTh.data('sort-dir', sortDir).addClass('sorting-' + sortDir);
			$thisTh.attr('aria-sort', sortDir === 'asc' ?
					'ascending' :
					'descending');

			$table.trigger('aftertablesort', {column: thIndex, direction: sortDir});

			$table.css('display');

		}, 10);

		return $thisTh;
	};

	// Call on a sortable td to update its value in the sort. This should be the
	// only mechanism used to update a cell's sort value. If your display value is
	// different from your sort value, use jQuery's .text() or .html() to update
	// the td contents, Assumes tablesorter has already been called for the table.
	$.fn.updateSortVal = function (newSortVal) {
		var $thisTd = $(this);
		if ($thisTd.is('[data-sort-value]')) {
			// For visual consistency with the .data cache
			$thisTd.attr('data-sort-value', newSortVal);
		}
		$thisTd.data('sort-value', newSortVal);
		return $thisTd;
	};

	$.fn.stripChars = function (val) {
		return val.replace(/[^0-9.]/g, '');
	}

	// ------------------------------------------------------------------
	// Default sorting settings
	// ------------------------------------------------------------------
	$.fn.tablesorter.dir = {ASC: 'asc', DESC: 'desc'};
	$.fn.tablesorter.defaultSortFns = {

		'int': function (a, b) {
			a = $.fn.stripChars(a);
			b = $.fn.stripChars(b);
			return parseInt(a, 10) - parseInt(b, 10);
		},
		'float': function (a, b) {
			a = $.fn.stripChars(a);
			b = $.fn.stripChars(b);
			return parseFloat(a) - parseFloat(b);
		},
		'string': function (a, b) {
			return a.localeCompare(b);
		},
		'string-ins': function (a, b) {
			a = a.toLocaleLowerCase();
			b = b.toLocaleLowerCase();
			return a.localeCompare(b);
		}
	};

	return Table;

}(jQuery))

export default Table;
