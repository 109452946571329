/**
 * Accordion
 * @module Accordion
 * @version 1.12.0
 */
import Util from '../_BDLBB/services/util';
import Component from '../_BDLBB/services/component';

// Accordion Classes
const HASJS_CLASS = 'accordion-hasjs';
const HEADING_CLASS = 'accordion-heading-link';
const HEADING_ACTIVE_CLASS = 'accordion-heading-active';
const CONTENT_WRAPPER_CLASS = 'accordion-content-wrapper';
const CONTENT_TRANSITIONING_CLASS = 'accordion-content-transitioning';
const CONTENT_CLOSED_CLASS = 'accordion-content-closed';
const CONTENT_CLASS = 'accordion-content';

// Accordion Data Attributes
const DATA = {
	OPEN: 'data-accordion-open',
	MULTIPLE: 'data-accordion-multiple',
	TRANSITION: 'data-accordion-transition',
};

/**
 * Create a new Accordion component
 * @class
 */
class Accordion {
	/**
	 * Initialise Accordion
	 * @public
	 * @param {Node} el - Containing Accordion element
	 * @return {Boolean} success
	 */
	constructor(el) {
		// Register component
		this.success = Component.registerComponent(el, this);
		if (!this.success) return false;

		// Add *-hasjs class
		el.classList.add(HASJS_CLASS);

		// Store containing element
		this._el = el;
		// Store array of element IDs
		this._ids = [];
		// Get array of all headings
		this._headings = Array.from(el.querySelectorAll(`.${HEADING_CLASS}`));
		// Get array of all content sections
		this._contentSections = Array.from(el.querySelectorAll(`.${CONTENT_WRAPPER_CLASS}`));
		// Check if multiple data attribute is set
		this._allowMultiple = el.getAttribute(DATA.MULTIPLE) !== null;

		// generate IDs
		this._generateIDs();

		// init ARIA attributes
		this._initAriaAttributes(el);

		// close all content sections
		this._closeAllContentSections();

		// Open specific section if anchor exists in URL
		const anchor = this._openAnchor();

		// open all sections with data attribute if no anchor present in URL
		if (!anchor) {
			this._contentSections.forEach((section, index) => {
				if (section.getAttribute(DATA.OPEN) !== null) {
					this._openContentSection(index, true);
				}
			});
		}


		// Attach events
		this._attachKeyboardEvents();
		this._attachLinkEvents();

		// Enable transitions
		// Done last so the initial close of content sections isn't transitioned
		this._useTransitions = Util.getEnableTransitions();
		this._transitionSpeeds = Util.getTransitionSpeeds();
		this._transitionViewport = el.getAttribute(DATA.TRANSITION) !== null;

		return this.success;
	}

	/**
	 * Set whether multiple components can be opened at once
	 * @public
	 * @param {Boolean} multiple
	 */
	setAllowMultiple(multiple) {
		this._allowMultiple = multiple;
	}

	/**
	 * Open section in accordion by id
	 * @public
	 * @param {String} id
	 */
	openSectionById(id) {
		for (const section of this._contentSections) {
			if (section.id === id) {
				const index = this._contentSections.indexOf(section);
				this._openContentSection(index);
			}
		}
	}

	/**
	 * Set whether viewport should transition to content when opened
	 * @public
	 * @param {Boolean} enable
	 * @note Does not work in IE
	 */
	setTransitionViewport(enable) {
		this._transitionViewport = enable;
	}

	/**
	 * Generate IDs for each section from the href
	 * @private
	 */
	_generateIDs() {
		this._headings.forEach(link => {
			const id = link.href.split('#')[1];

			// Store IDs in private object
			this._ids.push({
				tab: `${id}-tab`,
				panel: id,
			});
		});
	}

	/**
	 * Initialise ARIA attributes
	 * @private
	 * @param {Node} el - Containing Accordion element
	 */
	_initAriaAttributes(el) {
		el.setAttribute('role', 'presentation');
		this._headings.forEach((link, index) => {
			link.setAttribute('role', 'button');
			link.setAttribute('aria-expanded', false);
			link.setAttribute('id', this._ids[index].tab);
			link.setAttribute('aria-controls', this._ids[index].panel);
		});
		this._contentSections.forEach((section, index) => {
			section.setAttribute('aria-hidden', false);
			section.setAttribute('id', this._ids[index].panel);
			section.setAttribute('aria-labelledby', this._ids[index].tab);
		});
	}

	/**
	 * Attach keyboard events
	 * @private
	 */
	_attachKeyboardEvents() {
		this._headings.forEach((link, index) => {
			link.addEventListener('keydown', e => {
				if (/(40|39|38|37|36|35|32)/.test(e.keyCode)) {
					e.preventDefault();
				}

				if (/(40|39)/.test(e.keyCode)) { // Down/Right arrow
					if (index < this._headings.length - 1) {
						this._headings[index + 1].focus();
					} else {
						this._headings[0].focus();
					}
				} else if (/(38|37)/.test(e.keyCode)) { // Up/Left arrow
					if (index === 0) {
						this._headings[this._headings.length - 1].focus();
					} else {
						this._headings[index - 1].focus();
					}
				} else if (e.keyCode === 36) { // Home key
					this._headings[0].focus();
					this._openContentSection(0);
				} else if (e.keyCode === 35) { // End key
					this._headings[this._headings.length - 1].focus();
					this._openContentSection(this._headings.length - 1);
				} else if (e.keyCode === 32) { // Space key
					if (link.getAttribute('aria-expanded') === 'true') {
						this._closeContentSection(index);
					} else {
						this._openContentSection(index);
					}
				}
			});
		});
	}

	/**
	 * Attach click events to this._headings
	 * @private
	 */
	_attachLinkEvents() {
		this._headings
			.forEach((link, index) => {
				link.addEventListener('click', e => {
					e.preventDefault();
					if (link.getAttribute('aria-expanded') === 'true') {
						this._closeContentSection(index);
					} else {
						this._openContentSection(index);
					}
				});
			});
	}

	/**
	 * Set Content section element height to child height
	 * @private
	 * @param {Integer} index - Index of content section
	 * @param {Integer} height - Optional height (will override the child's height)
	 * @returns {Boolean} success
	 */
	_setContentSectionHeight(index) {
		const section = this._contentSections[index];
		if (section) {
			const height = section.querySelector(`.${CONTENT_CLASS}`).clientHeight || 0;
			section.style.maxHeight = `${height}px`;
			return height > 0;
		}
		return false;
	}

	/**
	 * Open specific section if anchor is present
	 * @private
	 * @returns {Boolean} success
	 */
	_openAnchor() {
		// Strip out illegal chars from hash
		const id = window.location.hash.replace(/[^0-9a-z_-]/gi, '');
		// Check if element with corresponding ID exists in the DOM
		const el = id && this._el.querySelector(`#${id}`);
		if (el) {
			// Open specific section
			this._openContentSection(this._contentSections.indexOf(el), true);
			return true;
		}
		return false;
	}

	/**
	 * Close all content sections
	 * @private
	 */
	_closeAllContentSections() {
		// Update all headings
		this._headings.forEach(heading => {
			heading.setAttribute('aria-expanded', false);
			heading.classList.remove(HEADING_ACTIVE_CLASS);
		});
		// Close all content sections
		this._contentSections.forEach((section, index) => {
			this._closeContentSection(index);
		});
	}


	/**
	 * Close content section
	 * @private
	 * @param {Integer} index - Index of content section to close
	 */
	_closeContentSection(index) {
		const heading = this._headings[index];
		const section = this._contentSections[index];

		// If section isn't closed
		if (heading && section && !section.classList.contains(CONTENT_CLOSED_CLASS)) {
			// Update heading
			heading.setAttribute('aria-expanded', false);
			heading.classList.remove(HEADING_ACTIVE_CLASS);

			if (this._useTransitions) {
				// To transition the content section closed, we must first manually set the height
				this._setContentSectionHeight(index);
				section.classList.add(CONTENT_TRANSITIONING_CLASS);
				// Pause before removing the height attribute
				setTimeout(() => {
					section.style.maxHeight = null;
					// Wait for transition to finish before updating the class
					setTimeout(() => {
						section.classList.add(CONTENT_CLOSED_CLASS);
						section.classList.remove(CONTENT_TRANSITIONING_CLASS);
					}, this._transitionSpeeds.medium - 1);
				}, 1);
			} else {
				// No transition, just add closed class
				section.classList.add(CONTENT_CLOSED_CLASS);
			}
			section.setAttribute('aria-hidden', true);
		}
	}

	/**
	 * Open content section
	 * @private
	 * @param {Integer} index - Index of content section to show
	 * @param {Boolean} noTransitions - Force disable transitions
	 */
	_openContentSection(index, noTransitions) {
		// Close all if multiple aren't allowed
		if (!this._allowMultiple) {
			this._closeAllContentSections();
		}
		const heading = this._headings[index];
		const section = this._contentSections[index];

		if (heading && section) {
			// Update headings
			heading.classList.add(HEADING_ACTIVE_CLASS);
			heading.setAttribute('aria-expanded', true);

			// Open content section
			if (!noTransitions && this._useTransitions) {
				// Remove closed/closing class and add opening class and height
				section.classList.remove(CONTENT_TRANSITIONING_CLASS);
				section.classList.remove(CONTENT_CLOSED_CLASS);
				section.classList.add(CONTENT_TRANSITIONING_CLASS);
				this._setContentSectionHeight(index);
				// Pause and remove opening class and height
				setTimeout(() => {
					section.classList.remove(CONTENT_TRANSITIONING_CLASS);
					section.style.maxHeight = null;
				}, this._transitionSpeeds.medium);
			} else {
				// No transition, just remove closing/closed classed
				section.classList.remove(CONTENT_TRANSITIONING_CLASS);
				section.classList.remove(CONTENT_CLOSED_CLASS);
			}
			section.setAttribute('aria-hidden', false);

			// Transition viewport if enabled
			if (!noTransitions && this._transitionViewport) {
				this._transitionViewportToSection(index);
			}
		}
	}

	/**
	 * Transition viewport to section
	 * @private
	 * @param {Integer} index - Index of content section to transition to
	 */
	_transitionViewportToSection(index) {
		// Test if scroll method exists
		if (window.scrollBy) {
			// Stop any existing transitions
			clearInterval(this._transitionViewportInterval);

			const link = this._headings[index];
			const headerHeight = Util.getHeaderHeight() + 10; // px height of header

			// Get destination
			const accordionPos = Util.getElementPosition(this._el).top - headerHeight;
			let currentPos = Util.getViewportPosition().top;
			const destination = accordionPos + (link.clientHeight * index);

			const interval = 100 / 6; // 60fps
			const distance = Math.abs(destination - currentPos);
			const scrollStep = distance / (this._transitionSpeeds.fast / interval);
			const down = destination > currentPos; // true = down, false = up
			// Start transition
			this._transitionViewportInterval = setInterval(() => {
				window.scrollBy(0, down ? scrollStep : -scrollStep);
				// test if we should stop scrolling
				const pos = Util.getViewportPosition().top;
				if (pos === currentPos ||
					(down && pos > destination) ||
					(!down && pos < destination)) {
					// Stop scroll
					clearInterval(this._transitionViewportInterval);
					window.scrollTo(0, destination);
				}
				currentPos = pos;
			}, interval);
		}
	}
}

// Initialise all elements on DOM ready
document.addEventListener('DOMContentLoaded', () => {
	Array.from(document.querySelectorAll('.js-accordion'))
		.forEach(el => new Accordion(el));
});

export default Accordion;
