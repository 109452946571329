/* global $:true */

import './bdlbb';

import Rating from './components/rating';
import Modal from './components/modal';
import './components/rte';
import './components/quicklinks';

import './components/bootstrap.carousel.js';
import './components/promo';
import './components/accordion';
import './components/representative_example';
import './components/hero.js';
import './components/carousel.js';
import './components/snippet';
import './components/anchor';
import './components/lazyloading';

// Search JS files

import './components/social-share';
import './components/responsive-tap';

import './components/navigation';

document.addEventListener('DOMContentLoaded', () => {
	Array.from(document.querySelectorAll('.js-rating'))
		.forEach((el) => new Rating(el));
	// Modal
	Array.from(document.querySelectorAll('.js-modal'))
		.forEach(el => new Modal(el));

	// Modal Mutation Observer
	function modalMutationHandler(mutationRecords) {
		// eslint-disable-next-line prefer-arrow-callback
		mutationRecords.forEach(function (mutation) {
			if (typeof mutation.addedNodes === 'object') {
				$(mutation.target).find('.js-modal').each(function () {
					// eslint-disable-next-line no-new
					new Modal(this);
				});
			}
		});
	}

	const targetNodes = $('BODY');
	const MutationObserver = window.MutationObserver || window.WebKitMutationObserver;
	const modalObserver = new MutationObserver(modalMutationHandler);
	const obsConfig = { childList: true, characterData: true, subtree: true };

	// Add a target node to the observer. Can only add one node at a time.
	targetNodes.each(function () {
		modalObserver.observe(this, obsConfig);
	});
});

window.bdl.Components.Rating = Rating;
window.bdl.Components.Modal = Modal;
