$(function() {
    // debulked onresize handler
    function on_resize(c,t){
        onresize=function(){
            clearTimeout(t);
            t=setTimeout(c,100)
        };
        return c
    }

    function pageNavMobile() {
        if($(document).width() < 768){
            $('.page-navigation').find('.main-navigation').addClass('sidenav-cssmenu mobile-nav');
        } else {
            $('.page-navigation').find('.main-navigation').removeClass('sidenav-cssmenu mobile-nav');
        }
    }

    on_resize(function() {
        pageNavMobile();
    })();


    $('.page-navigation-button').on('click', function () {
		$(".page-navigation .main-navigation").toggle();
		$(this).toggleClass('opened closed');
		if ($(this).hasClass('opened')) {
			$(this).attr('aria-expanded', 'true');
			disableMainContent(true);
		} else {
			$(this).attr('aria-expanded', 'false');
			$('.menubar > .has-sub > a').attr({ 'aria-expanded': false });
			$('.menubar > .has-sub > div').attr({ 'aria-hidden': true }).hide();
			$('.menubar > li.has-sub').each(function () {
				if ($(this).hasClass("opened")) {
					$(this).removeClass('opened').addClass('closed');
				}
			});
			disableMainContent(false);
		}
	});

    var collapseList = function collapseList() {
	  $('.page-navigation .main-navigation').attr('aria-expanded', 'false');
	  $('.menubar > .has-sub > a').attr({ 'aria-expanded': false });
	  $('.menubar > .has-sub > div').attr({ 'aria-hidden': true }).hide();
	  $('.menubar > li.has-sub').each(function () {
		  $('.menubar > li.has-sub').removeClass('opened').addClass('closed');
		  if ($('.page-navigation .main-navigation').hasClass("opened")) {
			  $('.page-navigation .main-navigation').removeClass('opened').addClass('closed');
		  }
	  });
	  disableMainContent(false);
    };

	//list collapse on pressing ESC key
	$("div.desktop-nav a, li.has-sub a[aria-haspopup]").on("keydown", e => {
		if (e.which == 27) {
			collapseList();
		}
	});

	//list collapse on tab/ down arrow of last list item
	$('li.has-sub').find('a:last').on("keydown", e => {
		if ((e.which == 9 && !e.shiftKey) || e.which == 40) {
			collapseList();
		}
	});

	//list expand on pressing space key
	$('.menubar > .has-sub > a').keypress(function (e) {
		e.preventDefault();
		if (e.which == 32) {
			openList(e);
		}
	});

	//list expand on click
	$('.menubar > .has-sub > a').on('click', function (e) {
		e.preventDefault();
		openList(e);
	});

	var openList = function openList(e) {
		$(e.target).closest('a').attr('aria-expanded', true);
		$(e.target).closest('a').parent('li').siblings().find('ul').attr({
			'aria-hidden': true
		}).hide();
		$(e.target).closest('a').parent('li').siblings().find('div').attr({
			'aria-hidden': true
		});
		$(e.target).closest('a').parent('li').siblings().find('a[aria-haspopup]').attr({
			'aria-expanded': false
		});
		$(e.target).closest('a').parent('li').siblings().find('li').removeClass('opened').addClass('closed');
		var childUL = $(e.target).closest('a').parent('li').find('.cssmenu > ul');
		var childDIV = $(e.target).closest('a').parent('li').find('.cssmenu');
		if ($(e.target).closest('a').parent('li').hasClass('opened')) {
			$(e.target).closest('a').attr('aria-expanded', false);
		}

		if (childUL.attr('aria-hidden') === 'false') {
			childUL.attr({
				'aria-hidden': true
			});
			childUL.find('ul').attr({
				'aria-hidden': true
			});
		} else {
			childUL.attr({
				'aria-hidden': false
			});
		}

		if (childDIV.attr('aria-hidden') === 'false') {
			childDIV.attr({
				'aria-hidden': true
			});
		} else {
			childDIV.attr({
				'aria-hidden': false
			});
		}

		disableMainContent(true);
	};

	// hide background content from mobile Screen reader virtual cursor
	var disableMainContent = function disableMainContent(trap) {
		if ($(document).width() < 768) {
			if (trap) {
				$('main>div.container-fluid, main>section, header, footer').attr('aria-hidden', 'true');
			} else {
				$('main>div.container-fluid, main>section, header, footer').removeAttr('aria-hidden');
			}
		}
	};

 	//keyboard nav using up/ down arrow keys
    $('ul.menubar li.has-sub').on('focus', 'a', function (event) {
        event.preventDefault();
        event.stopPropagation();
        var currentItem;
        $('li.has-sub a').keydown( function (e) {
        e.stopImmediatePropagation();
        switch (e.which) {
        //down arrow focuses on the next submenu item in dropdown
             case 40:
               e.preventDefault();
               e.stopPropagation();
               currentItem = $(':focus').parent();
               if($(currentItem).hasClass('has-sub')) {$(this).parent('li').find('.desktop-nav li:first a').focus();}
               else {$(currentItem[0].nextElementSibling).find('a').focus();}
               break;

		//up arrow focuses on the previous submenu item in dropdown
             case 38:
               e.preventDefault();
			   e.stopPropagation();
			   currentItem = $(':focus').parent();
			   $(currentItem[0].previousElementSibling).find('a').focus();
			   break;

             default:
               break;
            }
        });
    });

    $('.mobile-nav nav > ul > .has-sub > a').on('click', function(e) {
        e.preventDefault();
        $(this).attr('aria-expanded', true);
        $(this).parent('li').siblings().find('ul').attr({
            'aria-hidden': true
        }).hide();
        $(this).parent('li').siblings().find('a[aria-haspopup]').attr({
            'aria-expanded': false
        });

        if ($(this).parent('li').hasClass('opened')) {
            $(this).attr('aria-expanded', false);
        }
        if ($(this).attr('aria-expanded') === 'true') {
            $(this).next().attr({
                'aria-hidden': false
            });
        } else {
            $(this).next().attr({
                'aria-hidden': true
            });
            $(this).next().find('ul').attr({
                'aria-hidden': true
            });
        }
    });

    $('.menubar .has-sub .has-sub > a').on('click', function(e) {
        e.preventDefault();
        $(this).attr('aria-expanded', true);
        $(this).parent('li').siblings().find('ul').attr({
            'aria-hidden': true
        });
        $(this).parent('li').siblings().find('a[aria-haspopup]').attr({
            'aria-expanded': false
        });
        if ($(this).parent('li').hasClass('opened')) {
            $(this).attr('aria-expanded', false);
        }
    });

    $('.cssmenu-new .has-sub .has-sub a').on('click', function(e) {
        e.preventDefault();

        var childUL = $(this).siblings('ul');

        if (childUL.attr('aria-hidden') === 'false') {
            childUL.attr({
                'aria-hidden': true
            });
        } else {
            childUL.attr({
                'aria-hidden': false
            });
        }
    });

    $('.cssmenu-new li.has-sub > a, .cssmenu-new li.has-sub > li').on('click', function(e) {
        e.preventDefault();

        if ($('.log-in').hasClass('opened')) {
            $(".login-wrapper").hide();
            $('.log-in').removeClass('opened').addClass('closed');
        }

        var element = $(this);

        if (element.prop("tagName") === 'A') element = element.parent('li');
        if (element.hasClass('opened')) {
            element.find('ul')
                .slideUp();

            element
                .removeClass('opened')
                .addClass('closed')
                .find('.desktop-nav').hide();

        } else {

            element
                .removeClass("closed")
                .addClass('opened')
                .find(".desktop-nav")
                .show()
                .children('ul').slideDown();

            element.children('ul')
                .slideDown();

            element.siblings('li').find('.desktop-nav').hide();

            element.siblings('li.has-sub')
                .removeClass('opened')
                .addClass('closed')
                .children('ul')
                .slideUp();
        }

    });

	//list expand on pressing space key
    $('.cssmenu-new li.has-sub > a, .cssmenu-new li.has-sub > li').keypress(function (e) {
		e.preventDefault();
		if (e.which == 32) {
			if ($('.log-in').hasClass('opened')) {
				$(".login-wrapper").hide();
				$('.log-in').removeClass('opened').addClass('closed');
			}

			var element = $(this);

			if (element.prop("tagName") === 'A') element = element.parent('li');
			if (element.hasClass('opened')) {
				element.find('ul').slideUp();

				element.removeClass('opened').addClass('closed').find('.desktop-nav').hide();
			} else {

				element.removeClass("closed").addClass('opened').find(".desktop-nav").show().children('ul').slideDown();

				element.children('ul').slideDown();

				element.siblings('li').find('.desktop-nav').hide();

				element.siblings('li.has-sub').removeClass('opened').addClass('closed').children('ul').slideUp();
			}
		}
	});
});
