/**
 * General RTE
 */

$(function() {
    $('a[target="_blank"]').not('.button, .icon')
    					   .not(':has(span)')
    					   .not(':has(img)')
    					   .addClass('third-party');

    // Adding tooltip functionality
    if (typeof $('.tooltip').qtip === 'function') {
        $('.container-fluid .tooltip').qtip({
            position: {
                my: 'bottom center',
                at: 'top center',
                viewport: $(window)
            }
        });
    }

    // Converting Title attribute to Aria-Label on Footnote links
		$('sup a:not([aria-label])').each(function() {
			$(this).attr('aria-label', $(this).attr('title'));
			$(this).attr('title', '');
		})
});
