/*
	Carousel for Barclays CMS
	last updated: 30/11/15

	Designed to handle (detected with special classes indicated int he var)
	- standard jumbo
	- short jumbo (including dont resize if headline content = 0)
	- video jumbo
	- product jumbo
	- tabbed jumbo

	Features:
	- debounced resize handler
	- tabbed jumbo (dependancy = Bootstrap carousel)
	- tabbed jumbo with video
*/

$(() => {
	// debulked onresize handler
	function on_resize(c, t) {
		onresize = function () {
			clearTimeout(t);
			t = setTimeout(c, 100);
		};
		return c;
	}

	$('.carousel').find('.item').each(function () {
		if ($(this).hasClass('active')) {
			$(this).attr({ 'aria-hidden': false });
		} else {
			$(this).attr({ 'aria-hidden': true });
		}
	});

	// setup variables, establish the type of jumbo
	let $jumbo = $('.carousel'),
		isShortjumbo = $jumbo.hasClass('jumbo-short'),
		isTabbedjumbo = $jumbo.hasClass('carousel'),
		isIE8 = $('html').hasClass('is-ie8'),
		isIE9 = $('html').hasClass('is-ie9'),
		videoIndex = 0,
		videoLinks = [],
		BV;

	if (isTabbedjumbo) {
		$jumbo.carousel({
			interval: 15000 // 0 to pause
		}).attr('role', 'marquee').on('slide.bs.carousel').on('slid.bs.carousel', () => {
			$('.carousel').find('.item').each(function () {
				if ($(this).hasClass('active')) {
					$(this).attr({ 'aria-hidden': false });
				} else {
					$(this).attr({ 'aria-hidden': true });
				}
			});

			// find the indicators and append some sr-only text
			if ($jumbo.find('.carousel-indicators').length > 0) {
				const indicators = $jumbo.find('.carousel-indicators');
				const indicatorsN = $jumbo.find('.carousel-indicators li').length;

				$(indicators).find('li.active a').each(function () {
					$(this).find('.sr-only').remove();
					const indicatorsIndex = $(this).parent('li').index() + 1;
					$(this).append(`<span class="sr-only">Carousel page ${indicatorsIndex} of ${indicatorsN} selected</span>`);
				});
				$(indicators).find('li:not(.active)').removeAttr('aria-live').find('a').each(function () {
					$(this).find('.sr-only').remove();
					const indicatorsIndex = $(this).parent('li').index() + 1;
					$(this).append(`<span class="sr-only">Carousel page ${indicatorsIndex} of ${indicatorsN} </span>`);
				});
			}

			if (isIE8 || isIE9) {
				// update flexbox layout for new slide, could be optimised for specific div?
				Flexie.updateInstance();
			}
		});

		if ($(document).width() <= 767) {
			$('.carousel .jumbo-content').append($('.carousel .tabs'));
		}

		if ($(document).width() > 767) {
			$('.carousel .carousel-inner').append($('.carousel .tabs').css('top', '0'));
		} else {
			$('.carousel .jumbo-content').append($('.carousel .tabs'));
		}

		$jumbo.find('.control-play').on('click', (e) => {
			e.preventDefault();
			$jumbo.carousel('cycle');
			$jumbo.find('.control-play').hide();
			$jumbo.find('.control-pause').show().focus();
		});

		$jumbo.find('.control-pause').on('click', (e) => {
			e.preventDefault();
			$jumbo.carousel('pause');
			$jumbo.find('.control-pause').hide();
			$jumbo.find('.control-play').show().focus();
		});

		$jumbo.find('.control-left').on('click', (e) => {
			e.preventDefault();
			$jumbo.carousel('prev');
		});

		$jumbo.find('.control-right').on('click', (e) => {
			e.preventDefault();
			$jumbo.carousel('next');
		});


		$('.site-section-tab .log-in').on('click', function (e) {
			e.preventDefault();
			if ($(this).hasClass('opened')) {
				$jumbo.carousel('pause');
			} else {
				$jumbo.carousel('cycle');
			}
		});

		$jumbo.find('.carousel-indicators a').on('click', (e) => {
			e.preventDefault();

			setTimeout(() => {
				$('.carousel-inner .item.active').addClass('focus').focus();
				$jumbo.carousel('pause');
				$jumbo.find('.control-pause').hide();
				$jumbo.find('.control-play').show();
			}, 700);
		});

		const carouselSlides = document.querySelectorAll('.jumboitem');

		[].forEach.call(carouselSlides, currentSlide => {
			// When the focus enters the carousel, pause the control on carousel
			currentSlide.addEventListener('focusin', (event) => {
				if (event.currentTarget.classList.contains('active')) {
					$jumbo.carousel('pause');
					$jumbo.find('.control-pause').hide();
					$jumbo.find('.control-play').show();
				}
			});

			// When the focus leaves the carousel, play the control on carousel
			currentSlide.addEventListener('focusout', (event) => {
				if (event.currentTarget.classList.contains('active')) {
					$jumbo.carousel('cycle');
					$jumbo.find('.control-play').hide();
					$jumbo.find('.control-pause').show();
				}
			});
		});
	}
});
