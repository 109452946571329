/**
 * Utilities
 * @module Util
 * @version 1.13.0
 */
const BREAKPOINTS = {
	sm: 768,
	md: 1024,
	lg: 1440,
};

const ENABLETRANSITIONS = true;

const TRANSITIONSPEED = {
	fast: 0.15,
	medium: 0.3,
	slow: 0.6,
};

const Util = {
	/**
	 * Get breakpoint value
	 * @public
	 * @param {String} key - Breakpoint key
	 */
	getBreakpoint(key) {
		return BREAKPOINTS[key] || false;
	},

	/**
	 * Get enable transitions
	 * @public
	 */
	getEnableTransitions() {
		return ENABLETRANSITIONS;
	},

	/**
	 * Get all transition speeds
	 * @public
	 */
	getTransitionSpeeds() {
		const ts = TRANSITIONSPEED;
		return {
			fast: ts.fast * 1000,
			medium: ts.medium * 1000,
			slow: ts.slow * 1000,
		};
	},

	/**
	 * Get transition speed
	 * @public
	 * @param {String} key - Transition key
	 */
	getTransitionSpeed(key) {
		return TRANSITIONSPEED[key] * 1000 || false;
	},

	/**
	 * Get header height
	 * @public
	 */
	getHeaderHeight() {
		return parseInt(window.getComputedStyle(document.body, null)
			.getPropertyValue('padding-top'), 0);
	},

	/**
	 * Get viewport position
	 * @public
	 */
	getViewportPosition() {
		const doc = document.documentElement;
		const body = document.body;
		return {
			top: (window.pageYOffset || doc.scrollTop || body.scrollTop),
			left: (window.pageXOffset || doc.scrollLeft || body.scrollLeft),
		};
	},

	/**
	 * Get element's position in document
	 * @public
	 * @param {Node} el
	 */
	getElementPosition(el) {
		const box = el.getBoundingClientRect();
		const body = document.body;
		const doc = document.documentElement;

		const scrollTop = window.pageYOffset || doc.scrollTop || body.scrollTop;
		const scrollLeft = window.pageXOffset || doc.scrollLeft || body.scrollLeft;

		const clientTop = doc.clientTop || body.clientTop || 0;
		const clientLeft = doc.clientLeft || body.clientLeft || 0;


		return {
			top: Math.round((box.top + scrollTop) - clientTop),
			left: Math.round((box.left + scrollLeft) - clientLeft),
		};
	},

	/**
	 * Check if element is in view
	 * @public
	 * @param {Node} el
	 */
	isElementInView(el) {
		const rect = el.getBoundingClientRect();

		return (
			rect.top >= this.getHeaderHeight() &&
			rect.left >= 0 &&
			rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
			rect.right <= (window.innerWidth || document.documentElement.clientWidth)
		);
	},

};

export default Util;
