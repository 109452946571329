/**
 * Tab
 * @module Tab
 * @version 1.13.0
 */
import Util from '../services/util';
import Component from '../services/component';

const LIST_CLASS = 'tab-list';
const LIST_DROPDOWN_CLASS = 'tab-list-dropdown';
const PANEL_CLASS = 'tab-panel';
const CONTENT_CLASS = 'tab-content';
const CONTENT_CLOSING_CLASS = 'tab-content-closing';
const CONTENT_OPENING_CLASS = 'tab-content-opening';
const HEADING_CLASS = 'tab-heading';
const HEADING_ACTIVE_CLASS = 'tab-heading-active';
const DATA = {
	ACTIVE: 'data-tab-active',
};

// Enable dropdown on mobile
const MOBILE_DROPDOWN = true;

/**
 * Create a new Tab component
 * @class
 */
class Tab {
	/**
	 * Initialise Tab
	 * @public
	 * @param {Node} el - Containing Tab element
	 * @return {Boolean} success
	 */
	constructor(el) {
		// Register component
		this.success = Component.registerComponent(el, this);
		if (!this.success) return false;

		// Store containing element
		this._el = el;
		// Store array of element IDs
		this._ids = [];
		// Store interactive elements
		this._listContainer = el.querySelector(`.${LIST_CLASS}`);
		this._panelContainer = el.querySelector(`.${PANEL_CLASS}`);
		this._listItems = Array.from(el.querySelectorAll(`.${LIST_CLASS} a`));
		this._panelItems = Array.from(el.querySelectorAll(`.${CONTENT_CLASS}`));
		// Store active panel
		this._activePanel = -1;
		// Is component transitioning
		this._transitioning = false;
		// Is dropdown enabled
		this._dropdownEnabled = false;
		// Enable transitions
		// Force disable transitions for now
		// this._useTransitions = Util.getEnableTransitions();
		this._useTransitions = false;
		this._transitionSpeed = this._useTransitions ? Util.getTransitionSpeed('medium') : 0;

		// Generate IDs
		this._generateIDs();

		// Initialise ARIA attributes
		this._initAriaAttributes();

		// Show tab list
		this._listContainer.style.display = 'block';
		// Remove non-js Headings
		Array.from(this._panelContainer.querySelectorAll(`.${HEADING_CLASS}`))
			.forEach(el => el.parentNode.removeChild(el));

		// Attach events
		this._attachClickEvents();
		this._attachKeyboardEvents();

		// Watch for window resize and configure dropdown (if enabled)
		if (MOBILE_DROPDOWN) {
			this._initDropdown(el);
			let resizeTimeout;
			window.addEventListener('resize', () => {
				clearTimeout(resizeTimeout);
				resizeTimeout = setTimeout(() => {
					this._toggleDropdown(el);
				}, 250);
			});
		}

		// Set and open active tab
		const openPanel = parseInt(el.getAttribute(DATA.ACTIVE), 10) || 0;
		this._openPanel(openPanel);

		return this.success;
	}

	/**
	 * Generate IDs for each section from the href
	 * @private
	 */
	_generateIDs() {
		this._listItems.forEach(link => {
			const id = link.href.split('#')[1];

			// Store IDs in private object
			this._ids.push({
				tab: `${id}-tab`,
				panel: id,
			});
		});
	}

	/**
	 * Initialise ARIA attributes
	 * @private
	 */
	_initAriaAttributes() {
		this._listContainer.setAttribute('role', 'tablist');
		this._listItems.forEach((link, index) => {
			link.setAttribute('role', 'tab');
			link.setAttribute('id', this._ids[index].tab);
			link.setAttribute('aria-controls', this._ids[index].panel);
			link.setAttribute('aria-selected', false);
			link.setAttribute('aria-expanded', false);
		});
		this._panelItems.forEach((panel, index) => {
			panel.setAttribute('role', 'tabpanel');
			panel.setAttribute('id', this._ids[index].panel);
			panel.setAttribute('aria-labelledby', this._ids[index].tab);
			panel.setAttribute('aria-hidden', true);
		});
	}

	/**
	 * Attach click events
	 * @private
	 */
	_attachClickEvents() {
		this._listItems.forEach((link, index) => {
			link.addEventListener('click', e => {
				e.preventDefault();
				this._openPanel(index);
			});
		});
	}

	/**
	 * Attach keyboard events
	 * @private
	 */
	_attachKeyboardEvents() {
		this._listItems.forEach((link, index) => {
			link.addEventListener('keydown', e => {
				if (/(40|39|38|37|36|35|32)/.test(e.keyCode)) {
					e.preventDefault();
				}

				if (/(40|39)/.test(e.keyCode)) { // Down/Right arrow
					if (index < this._listItems.length - 1) {
						this._listItems[index + 1].focus();
					} else {
						this._listItems[0].focus();
					}
				} else if (/(38|37)/.test(e.keyCode)) { // Up/Left arrow
					if (index === 0) {
						this._listItems[this._listItems.length - 1].focus();
					} else {
						this._listItems[index - 1].focus();
					}
				} else if (e.keyCode === 36) { // Home key
					this._listItems[0].focus();
					this._openPanel(0);
				} else if (e.keyCode === 35) { // End key
					this._listItems[this._listItems.length - 1].focus();
					this._openPanel(this._listItems.length - 1);
				} else if (e.keyCode === 32) { // Space key
					this._openPanel(index);
				}
			});
		});
	}

	/**
	 * Close panels
	 * @private
	 */
	_closePanels() {
		this._panelItems.forEach(panel => {
			panel.classList.add(CONTENT_CLOSING_CLASS);
			panel.setAttribute('aria-hidden', true);
		});
		this._listItems.forEach(link => {
			link.classList.remove(HEADING_ACTIVE_CLASS);
			link.setAttribute('aria-expanded', false);
			link.setAttribute('aria-selected', false);
		});
	}

	/**
	 * Open panel
	 * @private
	 * @param {Integer} index - Index of the panel to open
	 */
	_openPanel(index) {
		// Override index if an invalid value is provided
		const i = this._panelItems[index] ? index : 0;
		if (i !== this._activePanel) {
			// Stop the previous transition from removing the closing class
			// if multple click events occur in a short interval
			clearTimeout(this._transitioning);

			this._closePanels();

			const panel = this._panelItems[i];
			const link = this._listItems[i];
			this._activePanel = i;

			// Activate new link
			link.classList.add(HEADING_ACTIVE_CLASS);

			// Keep dropdown in sync (if enabled)
			if (MOBILE_DROPDOWN) {
				this._dropdownContainer.querySelector('select')
					.selectedIndex = i;
			}

			// Wait and open the new panel
			this._transitioning = setTimeout(() => {
				panel.classList.remove(CONTENT_CLOSING_CLASS);
				panel.classList.add(CONTENT_OPENING_CLASS);
				this._transitioning = setTimeout(() => {
					panel.classList.remove(CONTENT_OPENING_CLASS);
					panel.setAttribute('aria-hidden', false);
					link.setAttribute('aria-expanded', true);
					link.setAttribute('aria-selected', true);
				}, this._transitionSpeed);
			}, this._transitionSpeed);
		}
	}

	/**
	 * Initialise dropdown element
	 * @param {Node} el - Containing tab element
	 */
	_initDropdown(el) {
		// Create dropdown element
		this._dropdownContainer = document.createElement('div');
		this._dropdownContainer.classList.add(LIST_DROPDOWN_CLASS);
		this._dropdownContainer.innerHTML = `
			<select class="select tab-select">
				${this._listItems.map(item =>
						`<option>${item.innerText}</option>`)}
			</select>
		`;
		// Check current window width and show appropriate element
		if (window.innerWidth < Util.getBreakpoint('sm')) {
			this._dropdownEnabled = true;
			this._listContainer.style.display = 'none';
		} else {
			this._dropdownEnabled = false;
			this._dropdownContainer.style.display = 'none';
		}
		// Attach element to DOM
		el.insertBefore(this._dropdownContainer, el.firstChild);
		// Attach dropdown events
		this._attachDropdownEvents();
	}

	/**
	 * Attach dropdown events
	 * @private
	 */
	_attachDropdownEvents() {
		const select = this._dropdownContainer.querySelector('select');
		select.addEventListener('change', () => {
			this._openPanel(select.selectedIndex);
		});
	}

	/**
	 * Toggle dropdown element
	 * @private
	 */
	_toggleDropdown() {
		const currentView = this._dropdownEnabled;
		this._dropdownEnabled = window.innerWidth < Util.getBreakpoint('sm');
		// Transitioning to Dropdown
		if (!currentView && this._dropdownEnabled) {
			this._listContainer.style.display = 'none';
			this._dropdownContainer.style.display = 'block';
		}
		// Transitioning to Tab
		if (currentView && !this._dropdownEnabled) {
			this._dropdownContainer.style.display = 'none';
			this._listContainer.style.display = 'block';
		}
	}
}

export default Tab;
