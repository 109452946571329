/**
 * Hero
 */

$(function() {
	// debulked onresize handler
	function onResize(c, t) {
		onresize = function () {
			clearTimeout(t);
			t = setTimeout(c, 100);
		};
		return c;
	}

	let isIE11 = false;
	function ifIe11() {
		if (navigator.userAgent.match(/Trident\/7\./)) {
			isIE11 = true;
		}
	}

	ifIe11();

	$(window).on('resize', () => {
		$('body').find('.jumbo-rep').each(function () {
			let repHeight = $(this).find('.representative-example').outerHeight(),
				overlayJumboHeight = $(this).find('.jumbo-body').outerHeight(),
				jumboHeight = $(this).outerHeight(),
				overlayJumboSpace = (jumboHeight - repHeight - 60),
				overlaySpace = 0;

			if (overlayJumboHeight > overlayJumboSpace) {
				overlaySpace = overlayJumboHeight - overlayJumboSpace + 50;
				$(this).css('height', `${jumboHeight + overlaySpace}px`);
				$(this).find('picture').css('height', `${jumboHeight + overlaySpace}px`);
				$(this).find('.jumbo-body').css('top', `${(overlaySpace - 30) / 2}px`);
			}
		});
	});

	function measureHeroHeight() {
		if ($(document).width() > 767) {
			$('body').find('.jumbo-advanced').each(function () {
				let repHeight = $(this).find('.representative-example').outerHeight();
				let heroHeight = $(this).outerHeight() - 40;
				let	overlayHeight = $(this).find('.jumbo-body').outerHeight();

				if (typeof repHeight === 'undefined') {
					let	overlaySpace = (heroHeight - overlayHeight) / 2;
					let	buttonsMargin = 60;

					$(this).find('.jumbo-body').css('top', `${overlaySpace}px`);
					$(this).find('.jumbo-buttons').css('margin-top', `-${buttonsMargin}px`);
				} else {
					let	overlaySpace2 = ( heroHeight -  repHeight -  overlayHeight) / 2;
					let	buttonsMargin2 = repHeight + 60;

					$(this).find('.jumbo-body').css('top', `${overlaySpace2}px`);
					$(this).find('.representative-example').css('margin-top', `-${repHeight}px`);
					$(this).find('.jumbo-buttons').css('margin-top', `-${buttonsMargin2}px`);
				}
			});

			if (isIE11) {
				// -----------Code for carousel starts----

				if ($('.carousel .jumbo img').length > 0) {
					// it exists
					$('body').find('.jumbo-rep').each(function () {
						$(this).find('.jumbo-body').css('height', 'auto');
						$(this).find('.representative-example').css('height', 'auto');
						let repHeight = $(this).find('.representative-example').outerHeight(),
							overlayJumboHeight = $(this).find('.jumbo-body').outerHeight(),
							jumboHeight = $(this).outerHeight(),
							overlayJumboSpace = (jumboHeight - repHeight - 60),
							overlaySpace = 0;

						if (overlayJumboHeight > overlayJumboSpace) {
							console.log(2);
							overlaySpace = overlayJumboHeight - overlayJumboSpace + 50;
							const imgHeight = jumboHeight + overlaySpace;
							$('.carousel .jumbo').css('height', `${imgHeight}px`);
							$('.carousel .jumbo picture').css('height', `${imgHeight}px`);
							$(' .jumbo-media').attr('style', 'height: 100% !important;');
							$(this).find('.representative-example').css('margin-top', `-${repHeight}px`);
							$(this).find('.representative-example').css('height', `${repHeight}px`);
						}
					});
				}

				// -------------carousel code ends-----
				else if ($('.jumbo-rep').length > 0) {
					$('body').find('.jumbo-rep').each(function () {
						let repHeight = $(this).find('.representative-example').outerHeight(),
							overlayJumboHeight = $(this).find('.jumbo-body').outerHeight(),
							jumboHeight = $(this).outerHeight(),
							overlayJumboSpace = (jumboHeight - repHeight - 60),
							overlaySpace = 0;

						if (overlayJumboHeight > overlayJumboSpace) {
							overlaySpace = overlayJumboHeight - overlayJumboSpace + 50;
							$(this).css('height', `${jumboHeight + overlaySpace}px`);
							$(this).find('picture').css('height', `${jumboHeight + overlaySpace}px`);
							$(this).find('.jumbo-body').css('top', `${(overlaySpace - 30) / 2}px`);
						}
					});
				}
			}
		} else {
			$('.jumbo-advanced .jumbo-body').css('top', 'initial');
			$('.representative-example').css('margin-top', '0');
			$('.jumbo-buttons').css('margin-top', 'initial');

			$('.carousel .active').find('.jumbo-media').each(function () {
				const heroImage = $(this).outerHeight() + 10;
				$('.carousel .tabs').css('top', `${+heroImage}px`);
			});
		}
	}

	if (!isIE11) {
		const resizeObserver = new ResizeObserver(() => {
			if ($(document).width() > 767) {
				$('body').find('.jumbo-rep').each(function () {
					$(this).find('.jumbo-body').css('height', 'auto');
					$(this).find('.representative-example').css('height', 'auto');
					let repHeight = $(this).find('.representative-example').outerHeight(),
						overlayJumboHeight = $(this).find('.jumbo-body').outerHeight(),
						jumboHeight = $(this).outerHeight(),
						overlayJumboSpace = (jumboHeight - repHeight - 60),
						overlaySpace = 0;

					if (overlayJumboHeight > overlayJumboSpace) {
						overlaySpace = overlayJumboHeight - overlayJumboSpace + 50;
						const imgHeight = jumboHeight + overlaySpace;
						$('.carousel .jumbo').css('height', `${imgHeight}px`);
						$('.carousel .jumbo picture').css('height', `${imgHeight}px`);
						$(' .jumbo-media').attr('style', 'height: 100% !important;');
						$(this).find('.representative-example').css('margin-top', `-${repHeight}px`);
						$(this).find('.representative-example').css('height', `${repHeight}px`);
					}

					if (repHeight === 0) {
						$('.carousel .jumbo').css('height', 'auto');
						$('.carousel .jumbo picture').css('height', 'auto');
						$(' .jumbo-media').attr('style', 'height: 100% !important;');
					}
				});
			}
		});

		const elem = document.querySelector('.jumbo-overlay-content');
		if (elem !== null)	{
			resizeObserver.observe(elem);
		}
	}

	onResize(() => {
		measureHeroHeight();
	})();

	$('.carousel').on('DOMSubtreeModified', () => {
		measureHeroHeight();
	});

	$('.carousel .active .jumbo-media').on('load', () => {
		measureHeroHeight();
	});
});
