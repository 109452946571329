/**
 * jQuery Extends
 * @module Extends
 * @version 1.9.0
 * @deprecated since 1.9.0
 */

/* eslint-disable */
(function($) {
	if (!$) return false;

	$.extend({
		debounce: function(fn,timeout,invokeAsap,context) {
			if(arguments.length === 3 && typeof invokeAsap !== 'boolean'){
				context = invokeAsap;
				invokeAsap = false;
			}

			var timer;

			return function(){
				var args = arguments;
				context = context || this;
				invokeAsap && !timer && fn.apply(context, args);

				clearTimeout(timer);

				timer = setTimeout(function(){
					!invokeAsap && fn.apply(context, args);
					timer = null;
				},timeout);
			};
		}
	});
})(jQuery);
