/**
 * Skiplinks
 */

$(function() {

	if ($('.jumbo-body .quicklink').length > 0) {
		$('.jumbo-body .quicklink').attr("id", "quicklinks");
	}
	else if ($('.jumbo-body .quicklink').length === 0) {
		if($('.side .quicklink').length > 0) {
			var sideQuicklinkElements = document.querySelectorAll('.side .quicklink');
			var firstSideQuicklink = sideQuicklinkElements[0];
			$(firstSideQuicklink).attr("id", "quicklinks"); //first Quicklink only	
		} else {
			var mainQuicklinkElements = document.querySelectorAll('.main .quicklink');
			var firstMainQuicklink = mainQuicklinkElements[0];
			$(firstMainQuicklink).attr("id", "quicklinks");
		}
	}
});
