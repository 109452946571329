/**
 * Accordion
 */

$(function() {
	$('body').find('.accordion[data-accordion-multiple]').each(function () {
		$(this).find('.accordion-content-wrapper').each(function () {
			$(this).removeClass('accordion-content-closed')
				.attr('data-accordion-open',"");
		})
	})
});
