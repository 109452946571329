/**
 * Modal
 * @module Modal
 * @version 1.13.0
 */
import Component from '../_BDLBB/services/component';
import Util from '../_BDLBB/services/util';

// Modal Aria Attribute
const ARIA_HIDDEN = 'aria-hidden';

// Modal Data Dismiss Attributes
const DATA_DISMISS = 'data-modal-dismiss';

// Modal Data use href attribute
const DATA_USEHREF = 'data-modal-use-href';

// Modal Data Dynamic Content Function
const DATA_DYNAMIC_CONTENT_FUNCTION = 'data-dynamic-content-function';

// Modal Data Dynamic Content Index
const DATA_DYNAMIC_CONTENT_INDEX = 'data-dynamic-content-index';

// Modal Data Title attribute
const DATA_MODAL_TITLE = 'data-modal-title';

// Data Functionality attribute
const DATA_FUNCTIONALITY = 'data-functionality';

// Button data-functionality value to close modal
const DATA_CLOSE_BUTTON = 'data-close-modal';


// Iframe element selector
const IFRAME = 'iframe';

// Modal Classes
const CLASSNAME = {
	OPEN: 'modal-open',
	SHOW: 'modal-show',
	ANIMATE: 'modal-content-animation',
	BACKDROP: 'modal',
	DIALOG: 'modal-dialog',
	CONTENT: 'modal-content',
	CLOSE: 'modal-close-btn'
};
// Tabbable elements
const TABELEMENTS = `
	a[href],
	area[href],
	input:not([disabled]),
	select:not([disabled]),
	textarea:not([disabled]),
	button:not([disabled]),
	[tabindex="0"]
`;
// Event Keys
const KEY = {
	TAB: 9,
	ESC: 27,
};

// Have event listeners been attached to iframe
let iframeListenersAdded = false;
let triggerElementSet = false;

/**
 * Create a new Modal component
 * @class
 */

class Modal {
	/**
	 * Initialise Modal
	 * @public
	 * @param {Node} el - Containing Modal element
	 * @return {Boolean} success
	 */
	constructor(el) {
		// Register component
		this.success = Component.registerComponent(el, this);
		if (!this.success) return false;

		// Callbacks object
		this._callbacks = {};

		// Store containing element
		this._el = el;

		// Store modal transition speed
		this._transitionSpeed = Util.getTransitionSpeed('medium');

		// Store html element tag
		this._html = document.querySelector('html');

		// Store modal Id
		this._Id = this._el.id;

		// Get array of all elements with [data-modal-target="${this._Id}"] or [data-functionality="modal:${this._Id}"]
		this._triggerElementList = document.querySelectorAll(
			`[data-modal-target="${this._Id}"], [data-functionality="modal:${this._Id}"]`);
		this._triggerElement = [];
		this.triggerElementLength = this._triggerElementList.length;
		for (let i = 0; i < this.triggerElementLength; i++) {
			this._triggerElement.push(this._triggerElementList[i]);
		}

		// If use href is enabled, add all elements to the trigger element array
		if (this._el.getAttribute(DATA_USEHREF) !== null) {
			const els = document.querySelectorAll(`[href='#${this._Id}']`);
			this._triggerElement = [...this._triggerElement, ...els];
		}

		// Get array of all element with dataset dismiss
		this._dismissElementsList = this._el.querySelectorAll(`[${DATA_DISMISS}]`);
		this._dismissElements = [];
		this._dismissElLength = this._dismissElementsList.length;
		for (let i = 0; i < this._dismissElLength; i++) {
			this._dismissElements.push(this._dismissElementsList[i]);
		}

		// Get content section (only applicable to basic modal)
		this._contentSection = this._el.querySelector(`.${CLASSNAME.CONTENT}`);

		// To enable tabbing through elements in an iframe, use the iframe selector
		this._modalSelector = this._el.querySelector(`${IFRAME}`) ? this._el.querySelector(`${IFRAME}`) : this._el;

		// Get array of all tabbable elements within the content section of basic modal or enable tabbing through elements in an iframe modal
		this._tabbableElementsList = this._modalSelector.querySelectorAll(TABELEMENTS);
		this._tabbableElements = [];
		this._tabbableElLength = this._tabbableElementsList.length;
		for (let i = 0; i < this._tabbableElLength; i++) {
			this._tabbableElements.push(this._tabbableElementsList[i]);
		}

		// Store first tabbable element of basic modal
		this.firstFocusableElem = this._tabbableElements[0];

		// Store last tabbable element of basic modal
		this.lastFocusableElem = this._tabbableElements[this._tabbableElements.length - 1];

		// Get close button at the top of modal
		this._closeButton = this._el.querySelector(`.${CLASSNAME.CLOSE}`);

		// Init default modal state
		this._initDefaultState(); // attach events
		this._events();

		return this.success;
	}

	/**
	 * Initialise ARIA attribute
	 * Add 'modal-close' class to modal
	 * @private
	 */
	_initDefaultState() {
		this._contentSection.setAttribute(`${ARIA_HIDDEN}`, 'true');
	}

	/**
	 * Execute Function by name
	 * @private
	 */
	_executeFunctionByName(functionName, context /*, args */) {
		let args, namespaces, func;

		if (typeof functionName === 'undefined') {
			throw 'function name not specified';
		}

		if (typeof eval(functionName) !== 'function') {
			throw functionName + ' is not a function';
		}

		if (typeof context !== 'undefined') {
			if (typeof context === 'object' && context instanceof Array === false) {
				if (typeof context[functionName] !== 'function') {
					throw context + '.' + functionName + ' is not a function';
				}
				args = Array.prototype.slice.call(arguments, 2);

			} else {
				args = Array.prototype.slice.call(arguments, 1);
				context = window;
			}

		} else {
			context = window;
		}

		namespaces = functionName.split(".");
		func = namespaces.pop();

		for (let i = 0; i < namespaces.length; i++) {
			context = context[namespaces[i]];
		}

		return context[func].apply(context, args);
	}

	/**
	 * Displaying Dynamic content in the modal
	 * @private
	 */
	_displayDynamicData(functionString, argString) {
		this._executeFunctionByName(functionString, argString);
	}

	/**
	 * Attach click events
	 * @private
	 */
	_events() {
		/**
		 * Attach click event to this._triggerElement
		 * @param {Event} e - Click event
		 */
		this._triggerElement.forEach(event => event.addEventListener('click', e => {
			e.preventDefault();

			// Assigning value to trigger element if not set
			if (!triggerElementSet) {
				this.focusedEl = $(e.target);
			}
			triggerElementSet = true;

			this.showModal();

			if (e.currentTarget.dataset.componentType !== "DuplicatedButton") {
				if ($('.modal-iframe').length > 0) {
					const modalUrl = $('.modal-show').attr('data-modal-src');
					const modalTitle = $('.modal-show').attr('data-modal-title');
					$('.modal-show .modal-iframe').empty().append(
						`<iframe class="modal-body modal-body-full-height" frameborder="0" src="${modalUrl}" title="${modalTitle}"></iframe>`
					);
				}
			}
		}));

		/**
		 * Attach click event to document in order to use event delegation on dynamically added elements to DOM
		 * @param {Event} e - Click event
		 */
		document.addEventListener('click', e => {
			const isModalTriggerButton = e.target && (e.target.getAttribute('data-modal-target') === this._Id);
			const isModalTriggerHref = e.target && (e.target.getAttribute(DATA_USEHREF) !== null && e.target.getAttribute('href') === `#${this._Id}`);
			const isDynamicDataModal = e.target && (e.target.getAttribute(DATA_DYNAMIC_CONTENT_FUNCTION));
			const hasDynamicDataIndex = e.target && (e.target.getAttribute(DATA_DYNAMIC_CONTENT_INDEX));

			if (isModalTriggerButton || isModalTriggerHref) {
				e.preventDefault();

				if (isDynamicDataModal) {
					if (hasDynamicDataIndex) {
						this._displayDynamicData(e.target.getAttribute(DATA_DYNAMIC_CONTENT_FUNCTION), e.target.getAttribute(DATA_DYNAMIC_CONTENT_INDEX));
					} else {
						this._displayDynamicData(e.target.getAttribute(DATA_DYNAMIC_CONTENT_FUNCTION), null);
					}

					this.showModal();
				}
			}
		});

		/**
		 * Attach click event to this._dismissElements array
		 * @param {Event} e - Click event
		 */
		this._dismissElements.forEach(event => event.addEventListener('click', e => {
			e.preventDefault();
			this.hideModal(event);
		}));

		/**
		 * Attach click event to modal backdrop
		 * @param {Event} e - Click event
		 */
		this._el.addEventListener('click', e => {
			if (e.target.classList.contains(CLASSNAME.BACKDROP) ||
				e.target.classList.contains(CLASSNAME.DIALOG)) {
				this.hideModal();
			}
		});

		/**
		 * Attach keyboard events to modal content section
		 * @param {Event} e - Click event
		 */
		this._contentSection.addEventListener('keydown', e => {
			switch (e.keyCode) {
				case KEY.TAB:
					if (iframeListenersAdded === false) {
						this.getTabItemsFromIframe();

						if (this._tabbableElements.length <= 1) {
							e.preventDefault();
							break;
						}
					}
					// help IE11 find first tabbable item after close button
					if ((iframeListenersAdded === true) && (this.tabableElementsIframe.length > 0)) {
						if (e.target == this._closeButton && this._closeButton == document.activeElement) {
							e.preventDefault();
							this.tabableElementsIframe[0].focus();
						}
					}

					if (e.shiftKey) {
						this._handleBackwardTab(e);
					} else {
						this._handleForwardTab(e);
					}
					break;
				case KEY.ESC:
					this.hideModal(e);
					break;
				default:
					break;
			}
		});

		this._closeButton.addEventListener('blur', e => {
			if (iframeListenersAdded === false) {
				this.getTabItemsFromIframe();
			}
		});
	}

	/**
	 * Add callback for public modal methods
	 * @public
	 * @param {String} event
	 * @param {Function} callback
	 */
	addCallback(event, callback) {
		if (typeof callback === 'function' && typeof event === 'string') {
			if (!this._callbacks[event]) {
				this._callbacks[event] = [];
			}
			this._callbacks[event].push(callback);
		}
	}

	/**
	 * Public method to show modal
	 * @public
	 */
	showModal() {
		// Update modal
		this._contentSection.setAttribute(`${ARIA_HIDDEN}`, 'false');
		this._el.classList.add(`${CLASSNAME.SHOW}`);

		// Add modal-open class to html element tag to disable page scroll
		this._html.classList.add(`${CLASSNAME.OPEN}`);

		// Add transition class
		this._contentSection.classList.add(`${CLASSNAME.ANIMATE}`);

		// Store trigger element
		this.focusedElemBeforeOpen = this.focusedEl;

		// Call callback
		if (this._callbacks.show) this._callbacks.show.forEach(fn => fn());

		// Redirect focus to close button
		window.setTimeout(() => this._closeButton.focus(), 500);

		// hide background content from Screen reader virtual cursor
		$('main>div.container-fluid, main>section, header, footer').attr('aria-hidden', 'true');

	}

	/**
	 * Public method to hide modal
	 * @public
	 */
	hideModal() {
		// Update modal
		this._contentSection.setAttribute(`${ARIA_HIDDEN}`, 'true');
		this._el.classList.remove(`${CLASSNAME.SHOW}`);

		// Remove modal-open class from html element tag to enable page scroll
		this._html.classList.remove(`${CLASSNAME.OPEN}`);

		// Remove transition class
		this._contentSection.classList.remove(`${CLASSNAME.ANIMATE}`);

		this._closeButton.blur();

		// Call callback
		if (this._callbacks.hide) this._callbacks.hide.forEach(fn => fn());
		$('.modal-iframe iframe').remove();

		iframeListenersAdded = false;
		triggerElementSet = false;

		$('main>div.container-fluid, main>section, header, footer').removeAttr('aria-hidden');

		// Set focus to the original focus element
		if (this.focusedElemBeforeOpen) {
			this.focusedElemBeforeOpen.focus();
		}

	}

	/**
	 * Private method to handle backward tab
	 * @private
	 * @param {Event} e - Click event
	 */
	_handleBackwardTab(e) {
		if (document.activeElement === this.firstFocusableElem) {
			e.preventDefault();
			this.lastFocusableElem.focus();
		}
	}

	/**
	 * Private method to handle forward tab
	 * @private
	 * @param {Event} e - Click event
	 */
	_handleForwardTab(e) {
		if (document.activeElement === this.lastFocusableElem) {
			e.preventDefault();
			this.firstFocusableElem.focus();
		}
	}

	/**
	 * Public method to add event listeners to the first and last tabbable elements in iframe
	 * @public
	 */
	addListenersToIframe() {
		this.firstFocusableElemIframe.addEventListener('keydown', e => {
			switch (e.keyCode) {
				case KEY.TAB:
					if (this.tabableElementsIframe.length === 0) {
						e.preventDefault();
						break;
					}

					if (e.shiftKey) {
						e.preventDefault();
						this.lastFocusableElemIframe.focus();
					} else {
						e.preventDefault();
						// help IE11 find first tabbable item after close button
						this.tabableElementsIframe[0].focus();
					}
					break;
				case KEY.ESC:
					this.hideModal(e);
					break;
				default:
					break;
			}
		});
		this.lastFocusableElemIframe.addEventListener('keydown', e => {
			switch (e.keyCode) {
				case KEY.TAB:
					if (this.tabableElementsIframe.length === 0) {
						e.preventDefault();
						break;
					}

					if (!e.shiftKey) {
						e.preventDefault();
						this.firstFocusableElemIframe.focus();
					}
					break;
				case KEY.ESC:
					this.hideModal(e);
					break;
				default:
					break;
			}
		});
		if (this.addedCloseBttn !== null) {
			this.addedCloseBttn.addEventListener('click', e => {
				e.preventDefault();
				this.hideModal(e);
			});
		}
	}

	/**
	 * Public method to get first and last tabbable elements in iframe
	 * @public
	 */
	getTabItemsFromIframe() {
		// Get iframe element
		this.elIframe = this._el.querySelector(`${IFRAME}`);

		if (this.elIframe) {

			const allModalElements = TABELEMENTS.concat(',iframe');

			// Get iframe contents
			this.tabItemsIframe = this.elIframe.contentWindow.document.getElementsByTagName(`body`)[0];
			this.addedCloseBttn = this.tabItemsIframe.querySelector(`[${DATA_FUNCTIONALITY}=${DATA_CLOSE_BUTTON}]`);


			if ((this.tabItemsIframe !== undefined) && (iframeListenersAdded === false)) {

				// Get array of all tabbable elements within the iframe
				this.tabbableElIframeList = this.tabItemsIframe.querySelectorAll(TABELEMENTS);
				this.tabableElementsIframe = [];
				this.tabbableElIframeLength = this.tabbableElIframeList.length;
				for (let i = 0; i < this.tabbableElIframeLength; i++) {
					this.tabableElementsIframe.push(this.tabbableElIframeList[i]);
				}

				// Get array of all tabbable elements in modal and include iframe
				this.containerElementsList = this._el.querySelectorAll(allModalElements);
				this.containerElements = [];
				this.containerElLength = this.containerElementsList.length;
				for (let i = 0; i < this.containerElLength; i++) {
					this.containerElements.push(this.containerElementsList[i]);
				}

				// Get position of iframe relative to other elements in modal
				this.iframePos = this.containerElements.indexOf(this.elIframe);

				// Store first tabbable element in modal (may or may not be in the iframe)
				this.firstFocusableElemIframe = this.containerElements[0];
				if (this.iframePos === 0) {
					this.firstFocusableElemIframe = this.tabableElementsIframe[0];
				}

				// Store last tabbable element in modal (may or may not be in the iframe)
				this.lastFocusableElemIframe = this.containerElements[0];

				if (this.tabableElementsIframe.length > 0) {
					this.lastFocusableElemIframe = this.tabableElementsIframe[this.tabableElementsIframe.length - 1];
					this.lastContainerElPos = this.containerElements.length - 1;
					if (this.iframePos < this.lastContainerElPos) {
						this.lastFocusableElemIframe = this.containerElements[this.lastContainerElPos];
					}
				}

				this.addListenersToIframe();
				iframeListenersAdded = true;
			}
		}
	}
}

export default Modal;
