/**
 * Icon
 * @module Icon
 * @version 1.13.0
 */
import Component from '../services/component';
import Icons from '../services/icons';

const PREFIX = 'icon';
const EMBED_ATTR = 'data-icon-embed';

/**
 * Create a new Icon component
 * @class
 */
class Icon {
	/**
	 * Initialise Icon
	 * @public
	 * @param {Node} el - element to embed SVG in to
	 * @param {String} cssName - Optional: name of css file that contains the icons
	 * @return {Boolean} success
	 */
	constructor(el) {
		// Register component
		this.success = Component.registerComponent(el, this);
		if (!this.success) return false;

		// Store containing element
		this._el = el;

		const name = this._getIconName();
		if (!name) return false;

		const icon = Icons.getIcon(name);
		if (!icon) return false;

		this.success = this._embedSVG(icon.svg);
		return this.success;
	}

	/**
	 * Get icon name
	 * @private
	 * @return {String} Icon name
	 */
	_getIconName() {
		for (const c of Array.from(this._el.classList)) {
			if (c.indexOf(`${PREFIX}-`) === 0) {
				return c.replace(`${PREFIX}-`, '');
			}
		}
		return false;
	}

	/**
	 * Embed SVG
	 * @private
	 * @param {String} svg - SVG as a string
	 * @return {Boolean} success
	 */
	_embedSVG(svg) {
		this._el.innerHTML = svg;
		this._el.removeAttribute(EMBED_ATTR);
		this._el.style.backgroundImage = 'none';
		this._el.querySelector('svg').style.width = this._el.clientWidth;
		this._el.querySelector('svg').style.height = this._el.clientHeight;
		return true;
	}
}

export default Icon;
