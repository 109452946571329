/**
 * Promo
 */

$(function() {
    $('.promo').find('a.button').each(function() {
    	var thisLink = $(this).attr('href');
    	var thisTarget = $(this).attr('target');
    	$(this).closest('.promo')
    		   .find('a.promo-link')
    		   .attr({'href': thisLink, 'target': thisTarget})
    })
})
