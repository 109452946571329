/**
 * Icons
 * @module Icons
 * @version 1.13.0
 */

let cached = false;
const cache = {};

const extractSVG = (cssText) => {
	const encodedIcon = cssText.split(');')[0].match(/US-ASCII,([^"']+)/);
	if (encodedIcon && encodedIcon[1]) {
		return decodeURIComponent(encodedIcon[1]);
	}
	return false;
};

const cacheIcons = () => {
	const css = Array.from(document.querySelectorAll('link[href*=".css"]'));
	for (const file of css) {
		const rules = file.sheet.cssRules ?
			file.sheet.cssRules : file.sheet.rules;
		for (const rule of Array.from(rules)) {
			const className = rule.cssText.split(' ')[0];
			if (className.indexOf('.icon-') === 0) {
				const name = className.replace(/\.icon-/g, '');
				const svg = extractSVG(rule.cssText);
				if (name && svg) {
					cache[name] = {
						name,
						svg,
					};
				}
			}
		}
	}
	cached = true;
};

const Icons = {
	/**
	 * Get icon
	 * @public
	 * @param {String} name - icon name
	 * @returns {String} icon
	 */
	getIcon(name) {
		if (!cached) {
			cacheIcons();
		}
		return cache[name];
	},
};

export default Icons;
