/**
 * Component Service
 * @module Component
 * @version 1.9.0
 */

const components = [];

function getAllComponents() {
	return components;
}

function getComponent(el) {
	for (const c of components) {
		if (c.el === el && c.component) {
			return c.component;
		}
	}
	return false;
}

function registerComponent(el, component) {
	if (getComponent(el)) {
		return false;
	}
	components.push({
		el,
		component,
	});
	return true;
}

export default {
	getAllComponents,
	getComponent,
	registerComponent,
};
