import Component from '../_BDLBB/services/component';

const getPreviousSiblings = (el) => {
	const previousSiblings = [];
	let indexEl = el.previousSibling;
	while (indexEl) {
		previousSiblings.push(indexEl);
		indexEl = indexEl.previousSibling;
	}
	return previousSiblings;
};

const getNextSiblings = (el) => {
	const nextSiblings = [];
	let indexEl = el.nextSibling;
	while (indexEl) {
		nextSiblings.push(indexEl);
		indexEl = indexEl.nextSibling;
	}
	return nextSiblings;
};

class Rating {
	constructor(el) {
		this.success = Component.registerComponent(el, this);
		if (!this.success) return false;

		this._el = el;
		this._controlRatingEls = Array.from(el.querySelectorAll('.control-rating'));
		this._rating = this._getRating();
		this.setRating = this.setRating.bind(this);

		Array.from(el.querySelectorAll('.control-rating')).forEach((controlRating) => {
			controlRating.addEventListener('click', (e) => {
				const rating = this._controlRatingEls.findIndex((el) => el === event.target);
				if (rating > -1) this.setRating(rating + 1);
			});

			controlRating.addEventListener('mouseenter', (e) => {
				const rating = this._controlRatingEls.findIndex((el) => el === event.target);
				if (rating > -1) this._renderRating(rating + 1);
			});

			controlRating.addEventListener('mouseleave', (e) => this._renderRating(this._rating));
		});

		return this.success;
	}

	_getRating() {
		const index = this._controlRatingEls.findIndex((el) => {
			const controlIndicatorEl = el.querySelector('.control-indicator')
			if (controlIndicatorEl) return controlIndicatorEl.classList.contains('outline');
		});
		return index === -1 ? this._controlRatingEls.length : index;
	}

	_renderRating(rating) {
		this._controlRatingEls.forEach((el, index) => {
			const controlIndicatorEl = el.querySelector('.control-indicator');
			if (controlIndicatorEl) {
				if (index <= rating - 1) {
					controlIndicatorEl.classList.remove('outline');
					controlIndicatorEl.classList.add('fill');
				} else {
					controlIndicatorEl.classList.remove('fill');
					controlIndicatorEl.classList.add('outline');
				}
			}
		});
	}

	getRating() {
		return this._rating;
	}

	/**
	 * set rating manually
	 * @param {rating} number Event or Element
	 */
	setRating(rating) {
		this._rating = Math.min(rating, this._controlRatingEls.length);
		this._renderRating(rating);
	}
}

export default Rating;
