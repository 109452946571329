/**
 * Snippet
 */

$(function() {
	$('.article-snippet').find('a.toggle').on('click', function(e){
		e.preventDefault();
		if ($(this).hasClass('collapsed')) {
			$(this).removeClass('collapsed').attr('aria-expanded', true);
			$(this).closest('.article-snippet').find('.collapse').slideToggle().addClass('in').attr('aria-hidden', false);

		} else {
			$(this).addClass('collapsed').attr('aria-expanded', false);
			$(this).closest('.article-snippet').find('.collapse').slideToggle().removeClass('in').attr('aria-hidden', true);
		}
	});

	$('.article-snippet').each(function () {
		// Making snippet clickable if there is single CTA
		if($(this).hasClass('clickable-snippet')) {
			if ($(this).find('a').length === 1) {
				$(this).css('cursor', 'pointer');
				// check if a href has target blank
				var snippetCTAhref = $(this).find('a').attr('href');
				var snippetCTAtitle = $(this).find('a').attr('title');
				// check if a href has target blank
				if ($(this).find('a').attr('target') === '_blank') {
					$(this).wrap( '<a href="' + snippetCTAhref + '" title="' + snippetCTAtitle + '" target="_blank" aria-label="' + snippetCTAtitle + '" tabindex="-1" class="snippet-link" data-component-type="Button"></a>' );
                    $(this).find('a').attr('data-component-type', 'DuplicatedButton');
				} else {
					$(this).wrap( '<a href="' + snippetCTAhref + '" title="' + snippetCTAtitle + '" aria-label="' + snippetCTAtitle + '" tabindex="-1" class="snippet-link" data-component-type="Button"></a>' );
					$(this).find('a').attr('data-component-type', 'DuplicatedButton');
				}
			}
		}

		// Fixing ARIA Label and TITLE for Call To Action (CTA)
		$(this).find('a.button').each(function() {
			var openWindowText = "opens in a new window";
			var openWindowTextWithComma = ", " + openWindowText;
			var ariaLabel = $(this).attr('aria-label');

			if (ariaLabel.endsWith(openWindowTextWithComma) ) {
				ariaLabel = ariaLabel.replace(openWindowTextWithComma, "");
				$(this).attr('aria-label', ariaLabel);
				$(this).attr('title', openWindowText);
			} else {
				$(this).attr('aria-label', ariaLabel);
				$(this).removeAttr('title');
			}
		});
	});
});
