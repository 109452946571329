/**
 * Representative Example
 */

$(function() {
    var repexampleColCount = $('.representative-example .representative-example-layout-columns .row').find('div[class^="col-sm-"]').length;

	if (repexampleColCount == 2) {
		$('.representative-example-layout-columns').find('div[class^="col-sm-"]').each(function() {
			$(this).addClass('col-xs-6');
		});
    }

	if (repexampleColCount == 3) {
		$('.representative-example-layout-columns').find('div[class^="col-sm-"]').each(function() {
			$(this).addClass('col-xs-4');
		});
    }

	if (repexampleColCount == 4) {
		$('.representative-example-layout-columns').find('div[class^="col-sm-"]').each(function() {
			$(this).addClass('col-xs-6');
		});
    }
})
