/*
 * Barclays Design Language
 * @desc BDL entry point
 * @version 1.11.0
 */

// Polyfills
import 'classlist-polyfill';

// Services
import Util from './_BDLBB/services/util';
import Component from './_BDLBB/services/component';

// Components
//	Overwriting the accordion component from BDLBB to fix accessibility defects.
import Accordion from './components/accordion-bdlbb';
import Icon from './_BDLBB/components/icon';
import Tab from './_BDLBB/components/tab';

// Legacy JS
import './_BDLBB/services/extends';
import Table from './_BDLBB/components/table';

// Initialise Components
document.addEventListener('DOMContentLoaded', () => {
	// Accordion
	Array.from(document.querySelectorAll('.js-accordion'))
		.forEach(el => new Accordion(el));
	// Icon
	Array.from(document.querySelectorAll('[data-icon-embed]'))
	.forEach(el => new Icon(el));
	// Tab
	Array.from(document.querySelectorAll('.js-tab'))
	.forEach(el => new Tab(el));
	// Table
	Array.from(document.querySelectorAll('.js-table'))
	.forEach(el => new Table(el));
});


/**
 * Public API
 * @public
 * @type {Object}
 */
const API = {
	Components: {
		Accordion,
		Icon,
		Tab,
		Table,
	},
	Util,
	getComponent: Component.getComponent,
};

window.bdl = API;
