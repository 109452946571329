$(function() {
	$('a[href*="#"]:not([href="#"]):not(.accordion-heading-link)').on('click', function(e) {
		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
		  var target = $(this.hash);
		  target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
		  if (target.length) {
		  	if($(document).width() > 1129){
			    $('html, body').animate({
			      scrollTop: target.offset().top - 135
			    }, 1000);
			} else {
				$('html, body').animate({
			      scrollTop: target.offset().top - 105
			    }, 1000);
			}
		  }
		}
	});
});

